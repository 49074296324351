let cf7submit = document.querySelector('.button--cf7');
if(cf7submit) {
	let checky = document.querySelector('input[name="cf7-rc"]');
	let brlbsbtn = document.querySelector('._brlbs-btn');
	if(checky && brlbsbtn) {
		checky.addEventListener('change',() => {
			brlbsbtn.click();
		});
	}

	cf7submit.addEventListener('click',() => {
		let form = cf7submit.closest('.wpcf7-form');
		const offsetTop = form.offsetTop - 150;
		let check = setInterval(() => {
			let status = form.getAttribute('data-status');
			if(status === 'invalid') {
				clearInterval(check);
				setTimeout(() => {
					scroll({
						top: offsetTop,
						behavior: "smooth"
					});
				},500);
			}
			if(status === 'sent') {
				clearInterval(check);
				setTimeout(() => {
					scroll({
						top: offsetTop,
						behavior: "smooth"
					});
				},500);
			}
		},100);
	},{passive: true});
}