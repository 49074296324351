import { setCookie, getCookie } from './helpers.js';

let verification = document.querySelector('.verification');
let accept = document.querySelector('.button--accept');
let reject = document.querySelector('.button--reject');

if(verification) {
	if(getCookie('age-verification')) {
		verification.remove();
	} else {
		verification.classList.add('flyin');
	}

	accept.addEventListener('click',(e) => {
		e.preventDefault();
		e.stopPropagation();
		// body.classList.remove('ageverification');
		// window.dispatchEvent(new Event("resize", { bubbles: true }));

		// if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
		// 	var evt = document.createEvent('UIEvents');
		// 	evt.initUIEvent('resize', true, false, window, 0);
		// 	window.dispatchEvent(evt);
		// } else {
		// 	window.dispatchEvent(new Event("resize", { bubbles: true }));
		// }

		// console.log('accept');
		setCookie('age-verification','verified',365);
		verification.classList.add('flyout');
	});
	reject.addEventListener('click',(e) => {
		e.preventDefault();
		e.stopPropagation();
		// console.log('reject');
		window.location.href = 'https://www.google.de/search?q=legales+alter+um+in+deutschland+bier+trinken+zu+d%C3%BCrfen';
	});
}