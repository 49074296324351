// let category_nav = document.querySelector('.woocommerce-navigation__categories .nav');
// if(category_nav) {
// 	let category_nav_wrapper = document.querySelector('.woocommerce-navigation__categories .navwrapper');
// 	let category_nav_wrapper_parent = document.querySelector('.woocommerce-navigation__categories');
// 	category_nav.addEventListener('click',() => {
// 		// category_nav_wrapper_parent.classList.toggle('open');
// 		// category_nav_wrapper.style.height = category_nav_wrapper.getBoundingClientRect().height === 35 ? category_nav.getBoundingClientRect().height + 'px' : '35px';
// 	},false);
// }

let category_nav = document.querySelector('.woocommerce-navigation__categories .nav');
if(category_nav) {
	let category_nav_wrapper = document.querySelector('.woocommerce-navigation__categories .navwrapper');
	let category_nav_wrapper_parent = document.querySelector('.woocommerce-navigation__categories');
	category_nav.addEventListener('click',() => {
		category_nav_wrapper_parent.classList.toggle('open');
		category_nav_wrapper.style.height = category_nav_wrapper.getBoundingClientRect().height === 35 ? category_nav.getBoundingClientRect().height + 'px' : '35px';
	},false);
}