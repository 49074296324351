/**
 * The sprite controller
 */
 import { Sprite } from "./sprite.js";

 export class SpriteController {
	/**
	 * @param {String} [selector] dom query selector for finding place to add a sprite
	 * @constructor
	 */
	 constructor(selector) {
	 	this.sprites = new Object();
		this.uuid = 1; //Date.now();
		const SELECTOR = selector || ".product__keyframe";

		Array.from(document.querySelectorAll(SELECTOR)).forEach((parentNode) => {
			// prevent multiple sprites per container
			// basically a dirty singleton
			if (!parentNode.getAttribute("sprite-id")) {
				this._create(parentNode);
			}
		});
		this._setIntersectionObserver(this.sprites);
	}

	/**
	 * Adds a sprite to the given location in the DOM and to the list of available sprites
	 * @private
	 * @param {Node} Parent node
	 * @returns the sprite object
	 */
	 _create(where) {
	 	if (typeof where === "string") {
	 		where = document.querySelector(where);
	 	}
	 	if (!where) {
	 		return false;
	 	}

		// collect things
		let spriteId = this.uuid++;
		let settings = {
			frameCount: where.getAttribute("data-frame-count"),
			frameSize: where.getAttribute("data-frame-size"),
			cols: where.getAttribute("data-frame-cols"),
			overlay: where.querySelector(".product__keyframe-loader"),
			canvas: where.querySelector(".sprite-canvas"),
			image: where.querySelector(".product__keyframe-image"),
		};

		// console.log(settings);

		settings.src = settings.image.getAttribute("data-src");
		// initialize canvas with some dimenions otherwise we can not draw
		settings.canvas.width = settings.frameSize;
		settings.canvas.height = settings.frameSize;
		let spriteObject = {
			id: spriteId,
			location: where,
			settings: settings,
			sprite: new Sprite(spriteId, where, settings),
		};
		where.setAttribute("sprite-id", spriteId);
		settings.canvas.setAttribute("animation-id", spriteId);
		this.sprites[spriteId] = spriteObject;
		return spriteObject;
	}

	/**
	 * On Change handler for the Intersection Observer
	 * on change we're going to cycle through all changed items
	 * and run an animation
	 * @private
	 * @param {Object} changes the change object
	 * @param {Object} observer the obersver
	 */
 // 	onChange = (changes, observer) => {
	// 	changes.forEach((change) => {
	// 		if (change.intersectionRatio > 0) {
	// 			this.sprites[change.target.getAttribute("animation-id")].sprite.start();
	// 			// stop watching this particular element
	// 			this.observer.unobserve(change.target);
	// 		}
	// 		return true;
	// 	});
	// };


	/**
	 * OnLoad we're sdding an IntersectionObserver to all sprite canvases.
	 * We want to animate all items which are just arrive in the viewport aka are becoming visible
	 * @private
	 * @param {Object} settings the settigings object
	 */
	 _setIntersectionObserver(settings) {
	 	if ("IntersectionObserver" in window) {
	 		/** event handler */
	 		window.addEventListener("load", () => {
	 			let elements, options, observer, key;
				// set options for the observer
				options = {
					root: null,
					rootMargin: "0px",
					threshold: 1.0,
				};
				// setup observer and watch all elements to observe
				this.observer = new IntersectionObserver((changes,observer) => {
					changes.forEach((change) => {
						if (change.intersectionRatio > 0) {
							// console.log('IO inside sprite-controller.js');
							// console.log(change.target);
							let parent = change.target.closest('.glide__slide--active');
							if(parent && !parent.classList.contains('custom-slider--has-initial-state')) this.sprites[change.target.getAttribute("animation-id")].sprite.start();
							// stop watching this particular element
							this.observer.unobserve(change.target);
						}
						return true;
					});
				}, options);
				// observe all canvas elements
				for (key in this.sprites) {
					this.observer.observe(this.sprites[key].sprite.settings.canvas);
				}
			},{passive: true});
	 	}
	 }

	/**
	 * Returns the currently active sprite object(s)
	 * @private
	 * @returns Sprite Object
	 */
	 _getActiveSprite() {
	 	let key;
	 	let result = [];
	 	for (key in this.sprites) {
	 		if (this.sprites[key].sprite.settings.status === "play") {
	 			result.push(this.sprites[key].sprite);
	 		}
	 	}
	 	return result;
	 }

	/**
	 * Returns a sprite object by id
	 * @private
	 * @private
	 * @param {String} spriteId
	 * @returns Sprite Object
	 */
	 _getSprite(spriteId) {
	 	if (!this.sprites[spriteId]) {
	 		throw new Error("Sprite not found", this.spriteId);
	 	}

	 	return this.sprites[spriteId];
	 }

	/**
	 * Plays a given sprite animation from the start
	 * @api
	 * @param {String} spriteId
	 * @returns Sprite Object
	 */
	 start(spriteId) {
	 	let spriteObject = this._getSprite(spriteId);
	 	spriteObject.sprite.start();
	 }

	/**
	 * Rewinds a given sprite animation to the start
	 * @api
	 * @param {String} spriteId
	 * @returns Sprite Object
	 */
	 rewind(spriteId) {
	 	let spriteObject = this._getSprite(spriteId);
	 	spriteObject.sprite.rewind();
	 }

	/**
	 * Stops a given or the current active sprite sprite animation
	 * @api
	 * @param {String} [spriteId] the sprite id
	 * @returns
	 */
	 stop(spriteId) {
	 	if (!spriteId) {
	 		this._getActiveSprite().forEach((item) => {
	 			item.stop();
	 		});
	 		return;
	 	}
	 	let spriteObject = this._getSprite(spriteId);
	 	spriteObject.sprite.stop();
	 	return;
	 }

	/**
	 * Stops a given or the current active sprite sprite animation
	 * @api
	 * @param {String} [spriteId] the sprite id
	 */
	 kill(spriteId) {
	 	if (!spriteId) {
	 		this._getActiveSprite().forEach((item) => {
	 			item.kill();
	 		});
	 		return;
	 	}
	 	let spriteObject = this._getSprite(spriteId);
	 	spriteObject.sprite.kill();
	 	return;
	 }
	}
