import { forEach } from './helpers.js';

export const links = () => {
	const a = document.querySelectorAll('a');
	forEach(a,function(index,link) {
		if(link.host !== window.location.host && link.host !== '' && !link.getAttribute('href').includes('mailto:')) {
			link.setAttribute('target', '_blank');
			link.setAttribute('rel', 'external nofollow noopener nofollow');
		}
	});
}