import { forEach } from './helpers.js';

export const productimage = () => {

	const productImageWidgets = document.querySelectorAll('.product-image');

	if (productImageWidgets){

		forEach(productImageWidgets, (index, productImage) => {
			const spots = productImage.querySelectorAll('.product-image__spot');

			// open popup when clicked on a spot
			if(spots){
				forEach(spots, (index,spot)=>{

					//open popup
					const pin_open = spot.querySelector('.pin__open');
					const pin_close = spot.querySelector('.pin__close');
					const popup_mobile = spot.nextElementSibling;
					const popup_desktop = spot.querySelector('.popup--desktop');

					pin_open.addEventListener("click", ()=>{

						forEach(spots, (index,s)=>{
							closePopup(productImage, s);
						});

						// toggle pin
						pin_open.classList.remove('active');
						pin_close.classList.add('active');

						// toggle popup
						popup_mobile.classList.add('show');
						popup_desktop.classList.add('show');

						// adapt mobile widget size if popup is too big
						const popup_height_mobile = popup_mobile.getBoundingClientRect().height;
						const headlines_height = productImage.querySelector('.product-image__headlines').getBoundingClientRect().height;
						const new_height = popup_height_mobile + headlines_height + 110;
						if (new_height > productImage.getBoundingClientRect().height ) productImage.style.height = new_height + 'px';

						// set popup position on desktop
						const popup_height_desktop = popup_desktop.getBoundingClientRect().height;
						// popup_desktop.style.bottom = popup_height_desktop + 65 + 'px';
						popup_desktop.style.bottom =  50 + 'px';
					});

					//close popup
					pin_close.addEventListener("click", ()=>{
						closePopup(productImage, spot);
					});

					//close popup mobile
					const pin_close_mobile = popup_mobile.querySelector('.pin__close');
					pin_close_mobile.addEventListener("click", ()=>{
						closePopup(productImage, spot);
					});

				});
			}
		});
	}

	function closePopup(productImage, spot){
		const pin_open = spot.querySelector('.pin__open');
		const pin_close = spot.querySelector('.pin__close');
		const popup_mobile = spot.nextElementSibling;
		const popup_desktop = spot.querySelector('.popup--desktop');

		// toggle pin
		pin_open.classList.add('active');
		pin_close.classList.remove('active');

		// toggle popup
		popup_mobile.classList.remove('show');
		popup_desktop.classList.remove('show');

		productImage.style.height = 'auto';
	}

}
