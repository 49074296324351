/**
 * The animation logic
 */
import gsap from "../_gsap/all.js";

/**
 * Animate a sprite based on its settings
 * @param {Object} settings
 */
const animate = function (settings) {
	let current = {
		frame: 0,
	};

	// paint first frame
	render(current, settings);

	// GSAP
	settings.animation = gsap.to(current, {
		frame: settings.frameCount - 1,
		duration: settings.frameCount / 60, // we want 60fps
		onUpdate: render,
		onUpdateParams: [current, settings],
		onComplete: done,
		onCompleteParams: [settings],
	});
};

/**
 * Reset the animation properties when the animation is done
 * Don't repaint it to the first frame because!
 * @param {Object} settings the settings object
 */
const done = (settings) => {
	settings.status = "stop";
	settings.droppedFrames = 0;
	settings.animation.kill();
};

/**
 * Renders one specific frame
 * @param {Object} current the current frame
 * @param {Object} settings the settings object
 */
const render = (current, settings) => {
	const canvas = settings.canvas;
	const context = canvas.getContext("2d");
	const image = settings.image;
	// round frames (GSAP gives us something like 18.32 because their frame number is relatedto their animation steps)
	current.frame = Math.ceil(current.frame);

	// handle repeating frames
	settings.droppedFrames = settings.droppedFrames + 1;
	if (
		settings.lastFrame == current.frame &&
		settings.droppedFrames < settings.dropFrame
	) {
		return;
	}
	settings.lastFrame = current.frame;
	settings.droppedFrames = 0;

	// draw on canvas
	context.clearRect(0, 0, canvas.width, canvas.height);
	context.drawImage(
		settings.image,
		settings.spriteImageCoords[current.frame].x,
		settings.spriteImageCoords[current.frame].y,
		settings.frameSize,
		settings.frameSize,
		0,
		0,
		settings.frameSize,
		settings.frameSize
	);
};

export { animate, render };
