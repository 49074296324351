/**
* A single sprite instance
*/

import gsap from "../_gsap/all.js";
import { animate, render } from "./animate.js";

let global_runner = true;

export class Sprite {

	// let spriteId = "";
	// let reference = null;
	// let status = false;

/**
 * Creates a new Sprite instance
 * @constructor
 * @param {String} spriteId the unique id of this sprite
 * @param {Node} where parent node for this sprite
 * @param {Object} settings JSON object with the settings for this sprite
 * @returns the sprite instance
 */
 constructor(spriteId, where, settings) {

 	this.spriteId = "";
 	this.reference = null;
 	this.status = false; 
 	this.loaderRunning = true;
 	
 	if (
 		!settings.frameCount ||
 		!settings.frameSize ||
 		!settings.cols ||
 		!settings.overlay ||
 		!settings.image ||
 		!settings.src
 		) {
 		throw new Error("Missing required sprite settings ");
 }

	// due to Math.floor, gsap repeats some frames
	// we're going to drop repeating frames to save
	// memory and cpu cycles
	settings.dropFrame = 3;

	this._calculateCoords(settings);

	this.spriteId = spriteId;
	this.settings = settings;

	this._setLoader(settings);
	settings.status = "created";
	return this;
}

/**
 * Calculates the coordinates of each image frame inside the sprites
 * based on the supplie settings
 * @private
 * @param {Object} settings the settings object
 */
 _calculateCoords(settings) {
 	settings.spriteImageCoords = [];

 	for (let i = 0; i < settings.frameCount; i++) {
 		let coords = {
 			x: (i % settings.cols) * settings.frameSize,
 			y: Math.floor(i / settings.cols) * settings.frameSize,
 		};
 		settings.spriteImageCoords.push(coords);
 	}
 }

/**
 * Ensures the sprite image has been loaded by checking the status
 * and setting up an event
 * @private
 * @param {Object} settings the settings object
 */
 _setLoader(settings) {
 	
 	settings.image.addEventListener("load", (event) => {
 		if (settings.image.complete && settings.image.naturalHeight !== 0) {
 			this._imageAvailable(settings);
 		}
 	},{passive: true});
 	
 	// else {
 	// 	this._imageAvailable(settings);
 	// }
 }

/**
 * Ensures the sprite image has been loaded.
 * Hides overlay. Running the animation is done by the
 * IntersectionObserver.
 * @private
 * @param {Object} settings the settings object
 */
 _imageAvailable(settings) {
 	// window.requestAnimationFrame(() => {
 		settings.status = "ready";

		// paint first frame before removing the overlay
		// if(global_runner) {
			// render({ frame: 0 }, settings);
			// global_runner = false;
		// }
		// console.log(global_runner);
		// global_runner = false;
		// return; 

		// hide overlay
		// gsap.fromTo(
		// 	settings.overlay,
		// 	{ opacity: 1 },
		// 	{
		// 		opacity: 0,
		// 		duration: 0.5,
		// 		ease: "power3.inOut",
		// 		onComplete: () => {
		// 			settings.overlay.style.display = "none";
		// 			// start this animation only if the element is visible
		// 			// this._start();
		// 			// this._stop();
		// 		},
		// 	}
		// 	);
	// });
}

/**
 * Removes the animation and releases the objects for garbage collection
 * @private
 * @returns the sprite object
 */
 _kill() {
 	this.settings.status = "killed";
 	if (this.settings.animation) {
 		this.settings.animation.kill();
 	}
 }
/**
 * Plays a given sprite sprite animation from the start
 * @private
 * @returns the sprite object
 */
 _start() {
 	if (this.settings.status === "play") {
 		return;
 	}
 	this.settings.status = "play";
 	if (this.settings.animation) {
 		this.settings.animation.kill();
 	}
 	animate(this.settings);
 }

/**
 * Stops a given sprite sprite animation
 * @private
 * @param {String} spriteId
 * @returns the sprite object
 */
 _stop() {
 	this.settings.status = "stop";
 	try {
 		this.settings.animation.paused();
 		this.settings.animation.pause(0);
 		this._kill();
 	} catch (err) {}
 }

/**
 * Rewinds a given sprite animation to the start
 * @private
 * @returns the sprite object
 */
 _rewind() {
	// dont' do anything if it's alreay on the first frame
	if (this.settings.status === "ready") {
		return;
	}
	this.reference = requestAnimationFrame((t) => {
		this.settings.status = "ready";
		// paint first frame
		render({ frame: 0 }, this.settings);
	});
}

/**
 * Plays a given sprite animation from the start
 * @api
 * @returns the sprite object
 */
 start() {
 	if (this.settings.status === "created") {
 		this._setLoader(this.settings);
 		return;
 	}
 	this.reference = requestAnimationFrame((t) => this._start(t));
 }

/**
 * Stops a given sprite animation
 * @api
 * @returns the sprite object
 */
 stop() {
 	this.reference = requestAnimationFrame((t) => this._stop(t));
 }

/**
 * Rewinds a given sprite animation to the start
 * @api
 * @returns the sprite object
 */
 rewind() {
 	this.reference = requestAnimationFrame((t) => this._rewind(t));
 }

/**
 * Removes the animation and releases the objectsfor garbage collection
 * @api
 * @returns the sprite object
 */
 kill() {
 	this.reference = requestAnimationFrame((t) => this._kill(t));
 }
}