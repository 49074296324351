import { forEach } from './helpers.js';

export const instagram = () => {
	let instagramInterval = setInterval(() => {
		const images = document.querySelectorAll('.MediaThumbnail__image');
		if(images.length > 0) {
			forEach(images,function(index,img) {
				img.setAttribute('width','450');
				img.setAttribute('height','450');
			});
			clearInterval(instagramInterval);
		}
	},500);
}