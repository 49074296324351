import { forEach } from './helpers.js';

export const setfacebooklink = () => {
    let facebookLinks = document.querySelectorAll('.social-media-channels__channel--facebook');
    if(facebookLinks.length > 0) {
        forEach(facebookLinks, function (index, facebookLink) {
            if (navigator.appVersion) {
                let fbID = facebookLink.getAttribute('data-attrid');
                let fbLink = '';
                if (navigator.appVersion.indexOf('iPhone') != -1 || navigator.appVersion.indexOf('iPad') != -1 || navigator.appVersion.indexOf('iPod') != -1) {
                    fbLink = 'fb://profile/' + fbID;
                    facebookLink.setAttribute('href', fbLink);
                } else if (navigator.appVersion.indexOf('Android') != -1) {
                    fbLink = 'fb://page/' + fbID;
                    facebookLink.setAttribute('href', fbLink);
                }
            }
        });
    }
}