import { forEach } from './helpers.js';
// import Glide from '@glidejs/glide';
import Glide from "./_collectionslider/_slider/glide.esm.js";

export const productslider = () => {
	let productsliders = document.querySelectorAll('.glide--productslider');
	if(productsliders.length > 0) {
		forEach(productsliders,(index,imageslider) => {
			let slider = new Glide(imageslider,{
				autoplay: 4000
			});
			// let glide_anchors = imageslider.querySelectorAll('.glide__anchor');
			// if(glide_anchors.length>0) {
			// 	slider.on('swipe.start', function () {
			// 		forEach(glide_anchors, (index, anchor) => {
			// 			anchor.style.pointerEvents = 'none';
			// 		});
			// 	});
			// 	slider.on('swipe.end', function () {
			// 		forEach(glide_anchors, (index, anchor) => {
			// 			anchor.style.pointerEvents = 'auto';
			// 		});
			// 	});
			// }
			slider.mount();
		});
	}
}
