import { forEach } from './helpers.js';
// import Glide from '@glidejs/glide';
import Glide from "./_collectionslider/_slider/glide.esm.js";

export const collection = () => {
	let productsliders = document.querySelectorAll('.glide--productslider');
	if(productsliders.length > 0) {
		forEach(productsliders,(index,productslider) => {
			let slider = new Glide(productslider).mount();
		});
	}
	const collectionselect = document.querySelector('.collection__select');
	if(collectionselect) {
		collectionselect.addEventListener('change',() => {
			const val = collectionselect.value;
			const offsetTop = document.querySelector('div[data-productid="' + val + '"]').offsetTop;
			scroll({
				top: offsetTop,
				behavior: "smooth"
			});
		});
	}

	let slideshows = document.querySelectorAll('.slideshow');
	if(slideshows.length > 0) {
		forEach(slideshows,(index,slideshow) => {
			let slides = slideshow.querySelectorAll('.collection__milieu__slide');
			let slides_max = slides.length - 1;
			let slide_prev = 0;
			let slide_current = 1;
			let slide_interval = setInterval(() => {
				slides[slide_prev].style.display = 'none';
				slide_prev = slide_current;
				slides[slide_current].style.display = 'block';
				if(slide_current >= slides_max) {
					slide_current = 0;
				} else {
					slide_current += 1;
				}
			},3000);
		});
	}
}