import { forEach } from '../helpers.js';
// import Glide from "./_slider/glide.esm.js";
// import "./_slider/custom-glide.js";
import "./_slider/custom-dropdown.js";

// if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
// 	var evt = document.createEvent('UIEvents');
// 	evt.initUIEvent('resize', true, false, window, 0);
// 	window.dispatchEvent(evt);
// } else {
// 	window.dispatchEvent(new Event("resize", { bubbles: true }));
// }

// --------------------------------------------------------------
// ------------------ ⬇️ WAS COMMENTED OUT BEFORE ⬇️ ------------------
// --------------------------------------------------------------

// import * as GlideAttention from "./_slider/custom-glide-attention.js";

// trigger size event to fix glidejs
// window.dispatchEvent(new Event("resize", { bubbles: true }));

// window.addEventListener("load", event => {
// 	let images = document.querySelectorAll('.product__keyframe-image');
// 	forEach(images,(image,index) => {
	
// 	});
// });

let collectionsliders = document.querySelectorAll('.glide--collectionslider');
if(collectionsliders.length > 0) {
	forEach(collectionsliders,(index,collectionslider) => {
		let slides = collectionslider.querySelectorAll('.glide__slide');
		let slider = new Glide(collectionslider);
		slider.on(['mount.after','run.after'], function(event) {
			const currentIndex = slider.index;
			slides[currentIndex].classList.add('test');
		});
		slider.mount();
	});
}