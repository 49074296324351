// require('intersection-observer');

import { setCookie, isIEorEDGE, forEach } from './helpers.js';

import './verification.js';
import { menu } from './menu.js';
import { merch } from './merch.js';
import { collection } from './collection.js';
import { product } from './product.js';
import './spritesheet.js';
import './_collectionslider/collectionslider.js';
import { imageslider } from './imageslider.js';
import { productslider } from './productslider.js';
import { video } from './video.js';
import './swiper.js';
import './form.js';
import { links } from './links.js';
import { instagram } from './instagram.js';
import { posfinderinput } from './posfinder.js';
import { setfacebooklink } from './socialmedia.js';
import './shop.js';
import { productimage } from './productimage.js';

document.addEventListener("DOMContentLoaded", () => {
	console.log('DOM ready');
	instagram();
	links();
	menu();
	merch();
	collection();
	product();
	imageslider();
	video();
	posfinderinput();
	setfacebooklink();
	productslider();
	productimage();
	// swiper();

	function replaceStringInInnerHTML(htmlString, searchString, replaceString) {
		const tempElement = document.createElement('div');
		tempElement.innerHTML = htmlString;
		tempElement.querySelectorAll('*').forEach(element => {
			element.innerHTML = element.innerHTML.replace(new RegExp(searchString, 'g'), replaceString);
		});
		return tempElement.innerHTML;
	}

	function observeWooCommerceErrorList(callback) {
		const observer = new MutationObserver((mutationsList, observer) => {
			for (const mutation of mutationsList) {
				if (mutation.type === 'childList' && mutation.addedNodes.length) {
					const addedNode = mutation.addedNodes[0];
					if (addedNode.tagName === 'UL' && addedNode.classList.contains('woocommerce-error')) {
						const htmlString = addedNode.innerHTML;
						addedNode.innerHTML = replaceStringInInnerHTML(htmlString, 'CANNOT_BE_ZERO_OR_NEGATIVE Must be greater than zero. If the currency supports decimals, only two decimal place precision is supported.', 'Es gab ein Problem mit deiner WooCommerce Session.');
						callback();
					}
				}
			}
		});
		observer.observe(document.body, { childList: true, subtree: true });
	}
	observeWooCommerceErrorList((mutation) => {
		// console.log('IT GOT ADDED DUDE');
	});
},{passive: true});

window.addEventListener('load',() => {
	console.log('PAGE loaded');

	document.body.dispatchEvent(new Event("wc_fragment_refresh"));

	window.dispatchEvent(new Event("resize", { bubbles: true }));
	if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
		var evt = document.createEvent('UIEvents');
		evt.initUIEvent('resize', true, false, window, 0);
		window.dispatchEvent(evt);
	} else {
		window.dispatchEvent(new Event("resize", { bubbles: true }));
	}
	let spritesheets = document.querySelectorAll('.product__keyframe-image');
	if(spritesheets.length > 1) {
		let image = spritesheets[0];
		if(image) {
			image.setAttribute('src',image.getAttribute('data-src'));
			let imageLoader = setInterval(() => {
				if(image.complete && image.naturalHeight !== 0) {
					forEach(spritesheets,(index,spritesheet) => {
						if(index !== 0) spritesheet.setAttribute('src',spritesheet.getAttribute('data-src'));
					});
					clearInterval(imageLoader);
				}
			},100);
		}
	}
},{passive: true});
