import { forEach } from './helpers.js';

const glides = document.querySelectorAll('.glide');
let callback = (entries, observer) => {
	entries.forEach(entry => {
		if (entry.isIntersecting) {
			let elem = entry.target;
			if (entry.intersectionRatio >= 0.25) {
				let target = entry.target;
				let attention = target.querySelector('.glide__attention');
				attention.classList.add('swipe--me');
				observer.unobserve(target);
			}
		}
	});
};
let options = {
	// root: glide,
	rootMargin: '0px',
	threshold: 1.0
}
const observer = new IntersectionObserver(callback, options);
forEach(glides,(index,glide) => {
	if(glide.querySelector('.glide__attention')) observer.observe(glide);
});