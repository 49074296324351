export const posfinderinput = () => {
	let posfinderinput = document.querySelector('.widget--posfinder .posfinder__input .input');
	if(posfinderinput) {
		let parent = posfinderinput.closest('.posfinder__input');
		posfinderinput.addEventListener('input',(e) => {
			if(posfinderinput.value !== '' && !parent.classList.contains('hasinput')) parent.classList.add('hasinput');
			else if(posfinderinput.value === '' && parent.classList.contains('hasinput')) parent.classList.remove('hasinput');
			else return;
		});
	}
}