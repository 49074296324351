import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { forEach, isMobile } from './helpers.js';

gsap.registerPlugin(ScrollTrigger); 

let canvases = document.querySelectorAll('.sprite-canvas-scroll');
if(canvases) {
  let animations = [];

  // function getImage(url){
  //   return new Promise(function(resolve, reject){
  //     var img = new Image()
  //     img.onload = function(){
  //       resolve(url)
  //     }
  //     img.onerror = function(){
  //       reject(url)
  //     }
  //     img.src = url
  //   })
  // }

  function calcCanvases(canvases) {
    forEach(canvases,(index,canvas) => {

      // console.log(canvas.scrollTop);
      // console.log(canvas.offsetTop);
      // console.log(canvas.getBoundingClientRect().y);

      if(animations[index]) {
        animations[index].scrollTrigger.kill();
        animations[index].kill();
      }

      let animation;

      const context = canvas.getContext("2d");

      const parent = canvas.closest('.sprite-container');

      const imageEl = new Image();
      const frameCount = 120;
      const cols = 10;
      const images = [];

      let frameSize = 0;

      if(window.innerWidth <= 768) {
        frameSize = 335;
        imageEl.src = parent.getAttribute('data-mobile-url');
      } else {
        frameSize = 720;
        imageEl.src = parent.getAttribute('data-desktop-url');
      }

      canvas.setAttribute('width',frameSize);
      canvas.setAttribute('height',frameSize);

      const airpods = {
        frame: 0
      };

      for (let i = 0; i < frameCount; i++) {
        let coords = {
          x: (i % cols) * frameSize,
          y: Math.floor(i / cols) * frameSize
        };
        images.push(coords);
      }

      animation = gsap.to(airpods, {
        frame: frameCount - 1,
        snap: "frame",
        scrollTrigger: {
          trigger: canvas,
          scrub: 0.5,
          // start: '-50% 50%'
          // start: "top bottom+=110px",
          // end: "bottom top-=110px"
          start: "top center",
          end: "bottom center"
        },
        onUpdate: render
      });
      animations[index] = animation;

      // console.log(gsap);

      function render() {
        // console.log('RENDER',imageEl);
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(imageEl, images[airpods.frame].x, images[airpods.frame].y, frameSize, frameSize, 0, 0, frameSize, frameSize); 
      }

      imageEl.onload = render;
    });
  } //calcCanvases
  calcCanvases(canvases);

  // let resizeTimeout;
  // window.addEventListener('resize',() => {
  //   clearTimeout(resizeTimeout);
  //   resizeTimeout = setTimeout(() => {
  //     calcCanvases(canvases);
  //     // console.log(animations);
  //   }, 500);
  // });
  // window.addEventListener('load',() => {
  //   calcCanvases(canvases);
  //   console.log(gsap);
  // });

}

let scroll_canvases = document.querySelectorAll('.sprite-canvas-scroll-new');
if(scroll_canvases) {
  function initSpecials(canvases) {
    forEach(canvases,(index,canvas) => {
      console.clear();
      let no = index;
      const context = canvas.getContext("2d");

      canvas.width = 720;
      canvas.height = 720;

      const dir = canvas.getAttribute('data-prod');
      console.log(dir);

      const frameCount = 120;
      const currentFrame = index => (`${(homeurl)}/wp-content/themes/karlsberg/_scroll/${dir}/${(no + 1).toString()}/img_${(index).toString()}.png`);

      const images = []
      const airpods = {
        frame: 0
      };

      for (let i = 0; i < frameCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
        images.push(img);
      }

      gsap.to(airpods, {
        frame: frameCount - 1,
        snap: "frame",
        scrollTrigger: {
          trigger: canvas,
          scrub: 0.5,
          start: "top center",
          end: "bottom center"
        },
        onUpdate: render
      });

      images[0].onload = render;

      function render() {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(images[airpods.frame], 0, 0);
      }
    });
  }
  initSpecials(scroll_canvases);
}

// -----------------------------------------------------------

let static_canvases = document.querySelectorAll('.sprite-canvas-static-new');
if(static_canvases) {

  let options = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0
  }

  let callback = (entries, observer) => {
    entries.forEach(entry => {
      if(entry.isIntersecting) {
        setTimeout(() => {
          let canvas = entry.target;
          const context = canvas.getContext("2d");

          canvas.width = 720;
          canvas.height = 720;

          const dir = canvas.getAttribute('data-prod');
          const frameCount = 120;
          const currentFrame = index => (`${(homeurl)}/wp-content/themes/karlsberg/_scroll/${dir}/img_${(index).toString()}.png`);
          const images = []
          const airpods = {
            frame: 0
          };
          for (let i = 0; i < frameCount; i++) {
            const img = new Image();
            img.src = currentFrame(i);
            images.push(img);
          }
          images[0].onload = render;
          function render() {
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(images[airpods.frame], 0, 0);
            if(airpods.frame < (frameCount - 1)) {
              airpods.frame += 1;
              setTimeout(() => { 
                render();
              },1);
            } else {
              observer.unobserve(canvas);
            }
          }
        },1000);
      }
    });
  };

  let observer = new IntersectionObserver(callback, options);

  function initSpecials(canvases) {
    forEach(canvases,(index,canvas) => {
      observer.observe(canvas);
    });
  }
  initSpecials(static_canvases);
}