import { forEach } from './helpers.js';
// import Glide from '@glidejs/glide';
import Glide from "./_collectionslider/_slider/glide.esm.js";

export const imageslider = () => {
	let imagesliders = document.querySelectorAll('.glide--imageslider');
	if(imagesliders.length > 0) {
		forEach(imagesliders,(index,imageslider) => {
			let slider = new Glide(imageslider).mount();
		});
	}
}