import { forEach } from './helpers.js';

export const video = () => {
	window.addEventListener('load',() => {
		const widgets = document.querySelectorAll('.widget--video');
		if(widgets.length >= 1) {
			forEach(widgets,(index,widget) => {
				let video = widget.querySelector('.video');
				if(video) {
					let player = video.querySelector('.player');
					let play = video.querySelector('.ccplay');
					console.log(player);
					console.log(play);
					if(player && play) {
						play.addEventListener('click',() => {
							if(player.paused) {
								player.play();
								play.classList.add('ccplay--hide');
							} else {
								player.pause();
								play.classList.remove('ccplay--hide');
							}
						},{passive: true});
						player.addEventListener('play',() => {
							play.classList.add('ccplay--hide');
						},{passive: true});
						player.addEventListener('pause',() => {
							play.classList.remove('ccplay--hide');
						},{passive: true});
						play.click();
					}
				}
			});
		}

		const animations = document.querySelectorAll('.widget--animation');
		if(animations.length >= 1) {
			forEach(animations,(index,animation) => {
				let video = animation.querySelector('video');
				if(video) video.play();
			});
		}

		const ytplayers = document.querySelectorAll('.ytplayer');
		// console.log(ytplayers);
		if(ytplayers.length >= 1) {
			// console.log('>= 1');
			var tag = document.createElement('script');
			tag.src = "https://www.youtube.com/player_api";
			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

			window.onYouTubePlayerAPIReady = () => {
				// console.log('GO');
				forEach(ytplayers,(index,ytplayer) => {
					let videoid = ytplayer.getAttribute('data-id');
					let player = new YT.Player(ytplayer, {
						videoId: videoid,
						playerVars: { 
							'rel': 0, 
							'loop': 1,
							'ecver': 2,
							'autoplay': 1, 
							'controls': 0,
							'showinfo': 0,
							'modestbranding': 1,
							'iv_load_policy': 3,
						},
						events: {
							'onReady': onPlayerReady,
							'onStateChange': onPlayerStateChange
						}
					});
					function onPlayerReady(event) {
						// console.log(event);
						player.mute();
					}
					function onPlayerStateChange(event) {
						// console.log(event);
						if(event.data === 0) player.seekTo(0);
					}
				});
			}
		}
	},{passive: true});
		}