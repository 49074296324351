import { forEach, swipedetect } from './helpers.js';

export const menu = () => {
	const menutoggles = document.querySelectorAll('.menu__toggle--hasdrawer');
	const menudrawers = document.querySelectorAll('.menu__drawer');
	const submenus = document.querySelectorAll('.sub-menu');
	const submenutriggers = document.querySelectorAll('.menu-item-has-children > a');
	const backdrop = document.querySelector('.backdrop');
	let lastindex = -1;
	forEach(menutoggles,(index,menutoggle) => {
		menutoggle.addEventListener('click',() => {
			if(lastindex != index) {
				backdrop.classList.remove('backdrop--active');
				forEach(menudrawers,(index,menudrawer) => { menudrawer.classList.remove('menu__drawer--open'); });
				forEach(menutoggles,(index,menutoggle) => { menutoggle.classList.remove('menu__toggle--active'); });
			}
			backdrop.classList.toggle('backdrop--active');
			menudrawers.item(index).classList.toggle('menu__drawer--open');
			menutoggle.classList.toggle('menu__toggle--active');
			lastindex = index;
		},{passive: true});
	});
	forEach(menudrawers,(index,menudrawer) => {
		let div = document.createElement('div');
		div.className = 'menu__close';
		menudrawer.insertAdjacentElement('afterbegin', div);

		swipedetect(div, function(swipedir) {
			if(swipedir === 'down') {
				menudrawer.classList.remove('menu__drawer--open');
				backdrop.classList.remove('backdrop--active');
			}
		});
	});
	forEach(submenutriggers,(index,submenutrigger) => {
		submenutrigger.removeAttribute('href','');
		submenutrigger.addEventListener('click',(e) => {
			e.preventDefault();
			e.stopPropagation();
			submenutrigger.classList.toggle('open');
			let submenu = submenutrigger.nextElementSibling;
			// submenu.style.height = submenu.getBoundingClientRect().height == 0 ? submenu.getAttribute('data-height') + 'px' : submenu.height = 0;
			// console.log(submenu.style.maxHeight);
			submenu.style.maxHeight = submenu.style.maxHeight == '0px' ? submenu.getAttribute('data-height') + 'px' : 0;
		});
	});

	window.addEventListener('load',() => {
		forEach(submenus,(index,submenu) => {
			// console.log(submenu.getBoundingClientRect().height);
			submenu.setAttribute('data-height',submenu.getBoundingClientRect().height);
			submenu.style.maxHeight = '0px';
		});
	},{passive: true});
}