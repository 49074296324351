import { TimelineMax, TweenMax, Elastic, Bounce, Linear } from "../_gsap/all.js";
import { spriteController } from "../_animation/main.js";

// we need to trigger window resize occassionally to trigger
// the resize of the glide.js instances.
// another problem occured when glide.js was places in position: absolute;
// so we need to use display:none / block to share the avaialble space for the animation

let animate = (obj) => {

	// console.log('animate inside custom-dropdown.js');

	let hideElement, showElement, properties;
	const TL = new TimelineMax({ pause: true });
	hideElement = document.querySelector(`[data-slider-id="${obj.from}"]`);
	showElement = document.querySelector(`[data-slider-id="${obj.to}"]`);

	// set animation properties
	// we're going
	// disable custom dropdown
	// window.resize to force glide.js dimensions
	// element to be hidden: block - element to be shown: none (done by gsap "from")
	// animate element to be hidden
	// force element to be shown: none - element to be hidden: block
	// window.resize to force element to be shown have the right dimensions
	// animate element to be shown
	// force element to be shown: block - element to be hidden: none (done by gsap "to")
	// window.resize to force element to be shown have the right dimensions
	// enable custom dropdown

	properties = {
		showDuration: 1.5,
		showFrom: { display: "none", scale: 0.75, opacity: 0 },
		showTo: { display: "block", opacity: 1, scale: 1, ease: Elastic.easeOut },
		hideDuration: 1.5,
		hideFrom: { display: "block", scale: 1, opacity: 1, x: 0 },
		hideTo: { display: "none", opacity: 0, scale: 1.25, ease: Elastic.easeIn },
	};

	hideElement.classList.add("glide--custom__after-dropdown");
	showElement.classList.add("glide--custom__after-dropdown");
	// necessary due to glidejs cloning elements
	Array.from(showElement.querySelectorAll('[data-glide-index="0"')).forEach(
		(item) => {
			item.classList.add("custom-slider--has-initial-state");
		}
		);
	// disable dropdown
	obj.select.setAttribute("disabled", true);

	/**
	 * keep this if you want the animation to start every time
	 * a slide with an animation enters the visible area.
	 * delete otherwise.
	 */
	// if the new frame has a keyframe animation: stop and reset it
	let animatedSprite = showElement.querySelector(".product__keyframe");
	if (animatedSprite) {
		let spriteId = animatedSprite.getAttribute("sprite-id");
		spriteController.stop(spriteId);
		spriteController.rewind(spriteId);
	}
	/** */






	// setup animation #1 - "hide current element"
	TL.fromTo(
		hideElement,
		properties.hideDuration,
		properties.hideFrom,
		properties.hideTo
		);
	// start the glide.js malakay
	// force the elements to the right state
	TL.set(hideElement, { display: "none" });
	TL.set(showElement, { display: "block" });
	TL.add(() => {
		// trigger size event to fix glidejs
		// window.dispatchEvent(new Event("resize", { bubbles: true }));

		if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
			var evt = document.createEvent('UIEvents');
			evt.initUIEvent('resize', true, false, window, 0);
			window.dispatchEvent(evt);
		} else {
			window.dispatchEvent(new Event("resize", { bubbles: true }));
		}

	});
	// setup animation #2 - "hide current element"
	TL.fromTo(
		showElement,
		properties.showDuration,
		properties.showFrom,
		properties.showTo,
		"+=0.25"
		);




	// final callback
	TL.eventCallback("onComplete", () => {
		// console.log('TL COMPLETED');

		// trigger size event to fix glidejs
		// window.dispatchEvent(new Event("resize", { bubbles: true }));

		if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
			var evt = document.createEvent('UIEvents');
			evt.initUIEvent('resize', true, false, window, 0);
			window.dispatchEvent(evt);
		} else {
			window.dispatchEvent(new Event("resize", { bubbles: true }));
		}

		obj.select.removeAttribute("disabled");
		// GlideAttention.run(showElement.getAttribute("data-slider-id"));

		/**
		 * keep this if you want the animation to start every time
		 * a slide with an animation enters the visible area.
		 * delete otherwise.
		 */
		// if the new frame has a keyframe animation: stop and reset it
		let prevEl = hideElement.querySelector(".product__keyframe");
		if(prevEl) prevEl.classList.add('is-playable');

		let animatedSprite = showElement.querySelector(".product__keyframe");
		if (animatedSprite && animatedSprite.classList.contains('is-mounted') && animatedSprite.classList.contains('is-playable')) {
			let spriteId = animatedSprite.getAttribute("sprite-id");
			spriteController.stop(spriteId);
			spriteController.rewind(spriteId);
			spriteController.start(spriteId);
		}
		/** */
	});

	// trigger size event to fix glidejs
	// window.dispatchEvent(new Event("resize", { bubbles: true }));

	if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
		var evt = document.createEvent('UIEvents');
		evt.initUIEvent('resize', true, false, window, 0);
		window.dispatchEvent(evt);
	} else {
		window.dispatchEvent(new Event("resize", { bubbles: true }));
	}

	// play animation
	TL.play();
	// trigger size event to fix glidejs
	// window.dispatchEvent(new Event("resize", { bubbles: true }));

	if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
		var evt = document.createEvent('UIEvents');
		evt.initUIEvent('resize', true, false, window, 0);
		window.dispatchEvent(evt);
	} else {
		window.dispatchEvent(new Event("resize", { bubbles: true }));
	}
};

document.body.addEventListener("change", (event) => {
	let select, from, to;
	select = event.target.closest(".js--custom-dropdown__toggle-custom-slider");

	if (select) {
		event.preventDefault();
		from = select.getAttribute("data-active");
		to = select.value;
		select.setAttribute("data-active", to);

		animate({ select: select, from: from, to: to });

		return false;
	}

	return true;
});
