import { forEach } from './helpers.js';
import Shuffle from 'shufflejs';
// import Glide from '@glidejs/glide';
import Glide from "./_collectionslider/_slider/glide.esm.js";

export const product = () => {
	const product_accordions = document.querySelectorAll('.product__accordion');
	if(product_accordions) {
		forEach(product_accordions,(index,accordion) => {
			let title = accordion.querySelector('.product__accordion__title');
			let content = accordion.querySelector('.product__accordion__content');
			let height = accordion.querySelector('.product__accordion__height');
			title.addEventListener('click',() => {
				accordion.classList.toggle('open');
				content.style.height = content.getBoundingClientRect().height == 0 ? height.getBoundingClientRect().height + 'px' : '0px';
			},{passive: true});
		});
	}
	const buybuttons_accordions = document.querySelectorAll('.buybuttons__accordion');
	if(buybuttons_accordions.length > 0) {
		forEach(buybuttons_accordions,(index,accordion) => {
			let title = accordion.querySelector('.buybuttons__accordion__title');
			let content = accordion.querySelector('.buybuttons__accordion__content');
			let height = accordion.querySelector('.buybuttons__accordion__height');
			title.addEventListener('click',() => {
				accordion.classList.toggle('open');
				content.style.height = content.getBoundingClientRect().height == 0 ? height.getBoundingClientRect().height + 'px' : '0px';
			},{passive: true});
		});
	}

	const shoplinks = document.querySelectorAll('.shop');
	if(shoplinks.length > 0) {
		forEach(shoplinks,(index,shoplink) => {
			let shop = shoplink.getAttribute('data-shop');
			let product = shoplink.getAttribute('data-product');
			// console.log(shop);
			// console.log(product);
			shoplink.addEventListener('click',() => {
				
				dataLayer.push({
					'event': 'purchase_visit_external',
					'supplier': shop,
					'productName': product,
					'source': 'Web'
				});
			});
		});
	}

	let containerslider = document.querySelector('.product__containers');
	if(containerslider) {
		let glide = containerslider.querySelector('.glide--containerslider');
		// console.log(containerslider);
		let slide = new Glide(glide, {
			// type: 'carousel',
			startAt: 0,
			perView: 4,
			// gap: 15,
			bound: true,
			breakpoints: {
				1024: {
					perView: 3
				}
			}
		});
		setTimeout(() => {
			slide.mount();
			if(parseInt(glide.getAttribute('data-length')) <= 4 && window.innerWidth > 1024) {
				slide.disable(); 
			} else if(parseInt(glide.getAttribute('data-length')) <= 3 && window.innerWidth <= 1024) {
				slide.disable(); 
			}
		},500);
	}

	const product_categories = document.querySelectorAll('.product__category');
	const product_masonry_items = document.querySelectorAll('.masonryitem');
	if(product_categories.length > 0 && product_masonry_items.length > 0) {
		forEach(product_categories,(index,category) => {
			category.addEventListener('click',() => {
				forEach(product_categories,(index,cat) => {
					cat.classList.remove('active');
				});
				category.classList.add('active');
				let catid = category.getAttribute('data-catid');
				if(catid !== "0") {
					console.log(catid);
					shuffleInstance.filter(catid);
				} else {
					shuffleInstance.filter();
				}
			});	
		});
		const shuffleInstance = new Shuffle(document.querySelector('.productmasonry'), {
			itemSelector: '.masonryitem',
			sizer: '.masonryitem-sizer',
		});
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const catid = urlParams.get('data_catid');
		if(catid) {
			let search = '.product__category[data-catid="' + catid + '"]';
			let filter = document.querySelector(search);
			let active = document.querySelector('.product__category.active');
			if(active) {
				active.classList.remove('active');
				filter.classList.add('inactive');
			}
			if(filter) {
				filter.classList.remove('inactive');
				filter.classList.add('active');
			}
			shuffleInstance.filter([catid]);
			// filter.click();
		}
	}
}