import { forEach, throttle } from './helpers.js';
// import Glide from '@glidejs/glide';
import Glide from "./_collectionslider/_slider/glide.esm.js";

export const merch = () => {
	let merchsliders = document.querySelectorAll('.glide--merchslider');
	if(merchsliders.length > 0) {
		forEach(merchsliders,(index,merchslider) => {
			let slider = new Glide(merchslider).mount();
		});
	}
	// const prices = document.querySelectorAll('.merch__price');
	// if(prices.length > 0) {
	// 	let waiter;
	// 	function reCalcPriceBubble() {
	// 		console.log('reCalc');
	// 		forEach(prices,(index,price) => {
	// 			setTimeout(() => {
	// 				let dimensions = price.getBoundingClientRect();
	// 				console.log({dimensions});
	// 				price.style.height = dimensions.width + 'px'; 
	// 			},100);
	// 		});
	// 	}
	// 	reCalcPriceBubble();
	// 	window.addEventListener('resize',throttle(function() {
	// 		clearTimeout(waiter);
	// 		waiter = setTimeout(reCalcPriceBubble, 250);
	// 	},15,this));
	// }
}